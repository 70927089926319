import React, { useContext, useEffect, useState } from 'react'
import { Nav, Navbar, ProgressBar } from 'react-bootstrap'
import UserInfo from './UserInfo'
import './sidebar.css'
import QuestionInfo from './QuestionInfo'
import Question from './Question'
import { ResponsesData, SelectedQuestion } from '../context'
import LiveExam from '../../../../Services/liveexam.services'
import { Answered, Marked, NotVisited } from '../../../../components/Marking/Marking'

interface IExamSidebar {
  questionIndex: any;
  sectionData: any
}

export default function ExamSidebar({ questionIndex, sectionData }: IExamSidebar) {

  const { selectedQuestionId, setSelectedQuestionId } = useContext<any>(SelectedQuestion);

  const [sideBarExapand, setSideBarExpand] = useState(true);

  const [questionData, setQuestionData] = useState<any[]>()
  const [questionStatus, setQuestionStatus] = useState<any>({
    markedForReview: [],
    answered: [],
    markedandAnswered: []
    // visited: []
  })


  const handleQuestionChange = (questionId: any) => {
    setSelectedQuestionId({ ...selectedQuestionId, question: questionId })
  }


  const checkifAnswered = (optionData: any) => {
    if (optionData.length <= 0) {
      return false
    }
    return optionData.find((data: any) => data.value) ? true : false
  }


  const getAllUserMarkedResponses = async () => {
    // const allQuestionInSection = sectionData && sectionData.questions;

    await LiveExam.getAllUserEncryptedOptions().then(res => {
      if (res.status === 200) {
        setQuestionData(res.data.data)

        let markedForReview: any[] = [];
        let answered: any[] = [];
        let markedandAnswered: any[] = [];
        // let visited :any[]= [];

        for (let enc_option of res.data.data) {
          const questionExistInSection = sectionData && sectionData.questions.find((question_data: any) => question_data === enc_option.questionId);
          if (!questionExistInSection) {
            continue;
          }
          if (enc_option.markedForReview === true) {
            if (enc_option.selectedOption && enc_option.selectedOption.length > 0 && checkifAnswered(enc_option.selectedOption)) {
              markedandAnswered.push(enc_option);
            }
            else {
              // if no option exist but with mark
              markedForReview.push(enc_option)
            }
          }
          else {
            if (checkifAnswered(enc_option.selectedOption)) {
              answered.push(enc_option);
            }
            else {
              // visited.push(enc_option);
            }
          }
        }

        setQuestionStatus({
          markedForReview,
          answered,
          markedandAnswered
          // visited
        })
      }
    })
  }



  const checkIfMarkedForReview = (questionId: string) => {
    const questionValue = questionStatus.markedForReview.find((question_data: any) => question_data.questionId === questionId);
    if (!questionValue) {
      return false
    }
    return true
  }


  const checkIfMarkedForReviewAndAnswer = (questionId: string) => {
    const questionValue = questionStatus.markedandAnswered.find((question_data: any) => question_data.questionId === questionId);
    if (!questionValue) {
      return false
    }
    return true
  }

  const checkIfCurrentQuestion = (questionId: string) => {
    return selectedQuestionId.question === questionId
  }

  const checkIfVisited = (questionId: string) => {
    const questionValue = questionStatus.answered.find((question_data: any) => question_data.questionId === questionId);
    if (!questionValue) {
      return false
    }
    return true
  }

  useEffect(() => {
    if (sectionData && sectionData.questions) {
      getAllUserMarkedResponses()
    }
  }, [questionIndex, sectionData])


  const calculateProgress = () => {
    let totalQuestions = sectionData && sectionData.questions.length;
    let answeredQuestions = questionStatus.answered.length;
    // let markedForReviewQuestions = questionStatus.markedForReview.length;
    let markedandAnsweredQuestions = questionStatus.markedandAnswered.length;
    // let visitedQuestions = questionStatus.visited.length;

    let totalAnswered = answeredQuestions + markedandAnsweredQuestions;

    let progress = (totalAnswered / totalQuestions) * 100;

    return Number(progress.toFixed(2));
  }

  return (
    <>
      {sideBarExapand ?
        <Navbar expand="lg" className="d-block p-0 xen-dashboard-sub-nav">
          <Nav defaultActiveKey="0" className="flex-column">
            <span className="fs-12 d-flex justify-content-center text-secondary fw-bold dcm-link-cursor mt-1" onClick={() => setSideBarExpand(false)}>
              {"<< Retract"}
            </span>
            <div className='mx-2 mt-2'>
              <div className='text-success fw-bold'>
                Section Progress ({calculateProgress() + "%"})
              </div>
              <ProgressBar now={calculateProgress()} variant='success' striped />
            </div>
            <hr />
            <QuestionInfo
              answered={questionStatus.answered.length}
              marked={questionStatus.markedForReview.length}
              // visited={questionStatus.visited.length}
              markedandAnswered={questionStatus.markedandAnswered.length}
              notVisited={sectionData && sectionData.questions.length - (questionStatus.answered.length + questionStatus.markedForReview.length + questionStatus.markedandAnswered.length)}
            />
            {/* <hr /> */}
            <div className="xen-total-questions">
              <ul>
                {sectionData && sectionData.questions && sectionData.questions.length > 0 && sectionData.questions.map((questionId: any, index: number) => {
                  return (
                    <Question
                      queType={
                        checkIfCurrentQuestion(questionId) ? "currently-on" :
                          checkIfMarkedForReview(questionId) ? "markedanswer" :
                            checkIfVisited(questionId) ? "hexagon" :
                              checkIfMarkedForReviewAndAnswer(questionId) ? "circle-blue" :
                                "notvisited"
                      }
                      queNumber={index + 1}
                      onClick={() => handleQuestionChange(questionId)}
                    />
                  )
                })}
              </ul>
            </div>
          </Nav>
        </Navbar>
        : <>
          <Navbar className="d-block xen-dashboard-sub-nav-small">
            <div>
              <span className="fs-12 d-flex justify-content-center text-secondary fw-bold dcm-link-cursor" onClick={() => setSideBarExpand(true)}>
                {"Expand >>"}
              </span>
              {sectionData && sectionData.questions && sectionData.questions.length > 0 && sectionData.questions.map((data: any, index: number) => {
                return (
                  <>
                    <div onClick={() => handleQuestionChange(data)} className="dcm-link-cursor xen-question-option-small d-flex justify-content-center align-items-center">
                      <span className={
                        checkIfCurrentQuestion(data) ? "currently-on-text" :
                          checkIfMarkedForReview(data) ? "markedanswer-text" :
                            checkIfVisited(data) ? "hexagon-text" :
                              checkIfMarkedForReviewAndAnswer(data) ? "answer-review-text" :
                                "text-muted"}> {index + 1} </span>
                    </div>
                  </>
                )
              })}
            </div>
          </Navbar>
        </>
      }
    </>
  )
}