import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Image from 'rc-image';
import './index.less';
import LiveExam from '../../Services/liveexam.services';
import getCache from '../../cache/Get.cache';
import { useIndexedDB } from 'react-indexed-db';
import ImageViewModal from '../ImageViewModal/ImageViewModal';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassArrowRight, faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { getCachedData } from '../../cache/Load.cache';
import "./ExamQuestionImage.css"

interface IExamQuestionImage {
    value: any
}

export default function ExamQuestionImage(props: IExamQuestionImage) {
    const [s3Url, setS3Url] = useState<string>();
    // const { add, getByID, getByIndex, getAll, deleteRecord } = useIndexedDB("dicom");
    const [decryptedImages, setDecryptedImages] = useState<any>([]);
    const [selectedImage, setSelectedImage] = useState(undefined);
    const [imageFullView, setShowImageFullView] = useState<boolean>(false);
    const [oldPropsValue, setOldPropsValue] = useState<string>();

    const requestS3Url = async () => {
        await LiveExam.getS3url().then((res) => {
            if (res.status === 200) {
                setS3Url(res.data)
            }
        })
    }

    useEffect(() => {
        requestS3Url();
    }, [])


    const onMount = async () => {
        if (s3Url) {
            const questionData = await getQuestionDataById();
            setOldPropsValue(JSON.stringify(props.value))
            setDecryptedImages(questionData)
        }
    }


    const getQuestionDataById = async () => {
        let decryptInfo: any[] = [];

        for (const questionImage of props.value) {

            const valueData = questionImage.questionData.map((data: any) => { return data });
            if (valueData && valueData.length > 0) {
                for (const value in valueData) {
                    // const decryptedData = await getByIndex("_id", valueData[value]);
                    // if (decryptedData) {
                    // console.log("Index db")
                    // decryptInfo.push(decryptedData.data);
                    // }
                    // else {
                    console.log("Cache")
                    const data = await getCachedData(s3Url + "/" + valueData[value]);
                    decryptInfo.push(data);
                    // }
                }
            }
        }
        return decryptInfo

    }



    const refreshSelectedDicom = () => {
        setSelectedImage(undefined)
    }

    useEffect(() => {
        if (oldPropsValue != JSON.stringify(props.value)) {
            refreshSelectedDicom()
            if (props.value) {
                onMount();
            }
        }
    }, [props.value, s3Url])

    // {
    //     selectedDicom >= 0 ? <div className="dicom-half-view">
    //         <div className="d-flex justify-content-center align-items-center">
    //             <Button variant="warning" size="sm" onClick={() => setShowDicomFullView(true)}>
    //                 View Full Screen
    //             </Button>
    //         </div>

    //         <DcmViewerHook
    //             dicomData={decryptedQuestions[selectedDicom]}
    //             onClose={() => setSelectedDicom(undefined)}
    //             controlId="layer_group_main_dicom"

    //         />
    //     </div> : <>
    //         {dicomMultiView()}
    //     </>
    // }

    const multiView = () => {
        return (<>
            {
                props.value &&
                <div className='m-2 p-2'>
                    <div className="mx-2">
                        <div className='pb-3'>
                            <Row className="mx-2">
                                {
                                    decryptedImages && decryptedImages.length > 0 && decryptedImages.map((image_data: any, index: any) => {
                                        return (
                                            <>
                                                <Col md={12}>
                                                    <TransformWrapper
                                                        initialScale={1}
                                                        initialPositionX={0}
                                                        initialPositionY={0}
                                                    >
                                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                            <div className="d-flex align-items-center flex-column">
                                                                <div className="d-flex justify-content-center align-items-center mb-2">
                                                                    {/* <Button variant="warning" size="sm" className='me-1' onClick={() => {
                                                                setSelectedImage(image_data)
                                                                // setShowImageFullView(true)
                                                            }}>
                                                                View Large Size
                                                            </Button> */}

                                                                    <Button variant="primary" size="sm" className='me-2' onClick={() => {
                                                                        setSelectedImage(image_data)
                                                                        setShowImageFullView(true)
                                                                    }}>
                                                                        Full Screen
                                                                    </Button>

                                                                    <Button onClick={() => zoomIn()} size="sm" variant="primary" className="me-2">Zoom <FontAwesomeIcon icon={faSearchPlus} /></Button>
                                                                    <Button onClick={() => zoomOut()} size="sm" variant="primary" className="me-2">Zoom <FontAwesomeIcon icon={faSearchMinus} /></Button>
                                                                    <Button onClick={() => resetTransform()} size="sm" variant="warning" className="me-2">Reset</Button>
                                                                </div>
                                                                <TransformComponent wrapperStyle = {{border: "1px solid #f1f1f1" }}>
                                                                    <div className="d-flex justify-content-center align-items-center exam-question-image-container">
                                                                        <img className = "exam-question-image" src={image_data} />
                                                                    </div>
                                                                </TransformComponent>
                                                            </div>)}
                                                    </TransformWrapper>
                                                </Col>
                                            </>

                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </div>
            }
        </>)
    }

    return (
        <>

            <ImageViewModal
                show={imageFullView}
                setShow={(status: boolean) => {
                    setShowImageFullView(status)
                    setSelectedImage(undefined)
                }}
                imageData={selectedImage}
            />

            {
                selectedImage ? <>
                    <div className="dicom-half-view">
                        <TransformWrapper
                            initialScale={1}
                            initialPositionX={0}
                            initialPositionY={0}
                        >
                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                <div className="d-flex justify-content-center align-items-center">
                                    <div >
                                        <div className="tools mb-3 d-flex justify-content-center align-items-center">
                                            <Button onClick={() => zoomIn()} size="sm" variant="primary" className="me-2">Zoom <FontAwesomeIcon icon={faSearchPlus} /></Button>
                                            <Button onClick={() => zoomOut()} size="sm" variant="primary" className="me-2">Zoom <FontAwesomeIcon icon={faSearchMinus} /></Button>
                                            <Button onClick={() => resetTransform()} size="sm" variant="warning" className="me-2">Reset</Button>
                                            <Button variant='danger' size="sm" className="me-2" onClick={() => setSelectedImage(undefined)}>Close</Button>
                                            <Button variant="warning" size="sm" onClick={() => setShowImageFullView(true)}>
                                                View Full Screen
                                            </Button>
                                        </div>
                                        <TransformComponent>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <img src={selectedImage} style={{ maxHeight: "100%", maxWidth: "90%" }} />
                                            </div>
                                        </TransformComponent>
                                    </div>
                                </div>
                            )}
                        </TransformWrapper>

                        {/* <div className='d-flex justify-content-center align-items-center'>
                            <img src={selectedImage} style={{ maxHeight: "80vh" }} />
                        </div> */}
                    </div>

                </> : multiView()
            }

        </>
    )
}