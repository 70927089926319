import { faBroom, faFlag, faFontAwesomeFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { IOptionsData } from '../../view/Private/Exam/ExamQuestionBody/ExamQuestion/ExamQuestion.options';

import "./options.css";
import useAutoSizeTextArea from '../../helpers/useAutoSizeTextArea';

interface ITextOption {
    label: any
    count: number
    onChange: any
    optionId: string,
    defaultValue?: IOptionsData
}

export default function TextOption(props: ITextOption) {

    const textRef = useRef<HTMLTextAreaElement>(null);

    useAutoSizeTextArea(textRef.current, props.defaultValue && props.defaultValue.value ? props.defaultValue.value : "");

    const handleChange = (value: any, name: any) => {
        props.onChange({
            ...props.defaultValue, optionId: props.optionId ,[name]: value
        })
    }

    return (
        <div className='mb-4'>
            <Form.Group>
                <Form.Label className="h5 d-flex justify-content-between align-items-center">
                    <div className="fs-14">
                        <span className="fw-bold">
                        {String((props.count + 9).toString(36).toUpperCase() + ".")}
                        </span>
                        <span className='ms-1'>
                            {props.label}
                        </span>
                    </div>
                    <div>
                        <span className='me-2'>
                            <FontAwesomeIcon icon={faBroom} style={{ cursor: "pointer" }} size="sm" onClick={()=>{
                                handleChange("", "value")
                            }}/>
                        </span>
                        <span>
                            {
                                props.defaultValue && props.defaultValue.flag  === true ?
                                <>
                                        <FontAwesomeIcon className="text-warning" icon={faFlag} onClick={() => handleChange(false, "flag")} />
                                    </>:
                                    <>
                                        <FontAwesomeIcon icon={faFontAwesomeFlag} onClick={() => handleChange(true, "flag")} style={{ cursor: "pointer" }} />
                                    </>
                            }
                        </span>
                    </div>
                </Form.Label>
                <Form.Control
                    as="textarea"
                    // rows={5}
                    onChange={(e:any) => handleChange(e.target.value, "value")}
                    name="value"
                    value={props.defaultValue && props.defaultValue.value ? props.defaultValue.value : ""}
                    ref  = {textRef}
                />
            </Form.Group>
        </div>
    )
}