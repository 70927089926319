import { useEffect } from "react";

const useAutoSizeTextArea = (ref: any, value:any) => {
    useEffect(() => {
        if(ref) {
            ref.style.height = "350px";
            const scrollHeight = ref.scrollHeight;
            ref.style.height = scrollHeight + "px";
        }
    }, [value, ref])
};

export default useAutoSizeTextArea;