import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ExamQuestionStatement from '../../../../../components/ExamQuestionStatement/ExamQuestionStatement';
import ExamQuestionOptions, { IOptionsData } from './ExamQuestion.options';
import ExamQuestionValue from './ExamQuestion.value';

import "./ExamQuestion.css";
import ExamQuestionImage from '../../../../../components/ExamQuestionImage/ExamQuestionImage';
import { ExamDetails } from '../../context';
interface IExamQuestion {
    questionData: any
    language: string,
    questionResponses: any
    onUserResponseChange: any
    userAnswers: IOptionsData[] | undefined
}

export default function ExamQuestion(props: IExamQuestion) {

    const [response, setResponse] = useState();

    const [layout, setLayout] = useState<any>('layout 1');

    const examDetails: any = useContext(ExamDetails);



    const getQuestionValue = () => {
        const questionValue = props.questionData?.value.find((data: any) => data.language === props.language)
        return questionValue;
    }


    const getQuestionOptions = () => {
        const questionValue = props.questionData?.options.filter((data: any) => data.language === props.language)
        return questionValue;
    }

    const getQuestionImages = () => {
        const questionValue = props.questionData?.image.filter((data: any) => data.language === props.language)
        return questionValue;
    }

    const getQuestionResponse = () => {
        const questionResponse = props.questionResponses.section === props.questionData.section;
        if (questionResponse) {
            const questionResponseData = props.questionResponses?.questions.map((response: any) => {
                return response;
            });
            setResponse(questionResponseData);
        }

    }

    const getQuestionStatement = () => {
        const questionValue = props.questionData?.statement.find((data: any) => data.language === props.language)
        return questionValue;
    }

    useEffect(() => {
        getQuestionResponse();
    }, [])

    useEffect(() => {
        const sectionDetails = examDetails?.examDetail?.sections?.find(section => String(section._id) === String(props.questionData.section));
        if(sectionDetails?.layout){
            setLayout(sectionDetails?.layout);
        }
    },[props.questionData])


    return (
        <>
            <Row className="dcm-body-row-main">
                <Col md={layout == 'layout 1' ? 8 : 12 } className="exam-statement mt-2">
                    {/* <div className="exam-statement" > */}
                    <ExamQuestionStatement
                        statement={getQuestionStatement()}
                    />
                    <ExamQuestionImage
                        value={getQuestionImages()}
                    />
                    <div className='bg-muted'>
                    <ExamQuestionValue
                        value={getQuestionValue()}
                        response={response}
                        statement={getQuestionStatement()}
                    />
                    </div>
                    

                    {/* </div> */}
                </Col>
                <Col md={layout == 'layout 1' ?  4 : 12}>
                    <div className="dcm-options-main mt-1">
                        <div className='text-secondary h5'>
                            Answer:
                        </div>
                        <ExamQuestionOptions
                            options={getQuestionOptions()}
                            onChange={props.onUserResponseChange}
                            userAnswers={props.userAnswers}
                        />
                    </div>
                </Col>
                <Col md={12}>
                   
                </Col>
            </Row>




        </>
    )
}