import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./DicomViewModal.css";

interface IImageViewModal {
  show: boolean
  setShow: any
  imageData: any
}

export default function ImageViewModal(props: IImageViewModal) {

  return (
    <>
      <Modal show={props.show} fullscreen={true} onHide={() => props.setShow(false)} centered className="dicom-modal-fullscreen">
        <Modal.Title className="text-white">
            Image Control View</Modal.Title>
        <Modal.Body>
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div className="d-flex justify-content-center align-items-center">
                <div >
                    <div className="tools mb-3 d-flex justify-content-center align-items-center">
                      <Button onClick={() => zoomIn()} variant="primary" className="me-2">Zoom +</Button>
                      <Button onClick={() => zoomOut()} variant="primary" className="me-2">Zoom -</Button>
                      <Button onClick={() => resetTransform()} variant="warning" className="me-2">Reset</Button>
                      <Button onClick={() => props.setShow(false)} variant="danger" className="me-2">Close</Button>
                    </div>
                  <TransformComponent>
                      <div className="d-flex justify-content-center align-items-center" style = {{
                        width: "1500px",
                        height: "80vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden"
                      }}>
                        <img src={props.imageData} style = {{maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto"}} />
                      </div>
                  </TransformComponent>
                </div>
              </div>
            )}
          </TransformWrapper>
        </Modal.Body>
      </Modal>
    </>
  );
}